import React, {useState} from 'react';
import "./Header.css";
import CV from "../../assets/John-Cv.pdf";

const Header = () => {
    /*======= Change Background Header =======*/
    window.addEventListener("scroll", function () {
        const header = this.document.querySelector(".header");
        if (this.scrollY >= 80) header.classList.add ("scroll-header");
        else header.classList.remove("scroll-header");
    });
    /*============== Toggle Menu ============*/
    const [Toggle, showMenu] = useState(false);
    const [activeNav, setActiveNav] = useState("#home");

    return (
        <header className='header'>
            <nav className='nav container'>
                <a href='index.html' className='nav__logo'>Portfolio</a>
                <div className={Toggle ? 'nav__menu show-menu' : 'nav__menu'}>
                    {/* ------------ Menu List ------------ */}
                    <ul className='nav__list grid'>
                        {/* ------------ Home menu button ------------ */}
                        <li className='nav__item'>
                            <a href='#home' onClick={() => setActiveNav ("#home")} className={activeNav === "#home" ? "nav__link active-link" : "nav__link"}>
                                <i className='uil uil-estate nav__icon'></i> Home
                            </a>
                        </li>
                        {/* ------------ About menu button ------------ */}
                        <li className='nav__item'>
                            <a href='#about' onClick={() => setActiveNav ("#about")} className={activeNav === "#about" ? "nav__link active-link" : "nav__link"}>
                                <i className='uil uil-user nav__icon'></i> About
                            </a>
                        </li>
                        {/* ------------ Skills menu button ------------ */}
                        <li className='nav__item'>
                            <a href='#skills' onClick={() => setActiveNav ("#skills")} className={activeNav === "#skills" ? "nav__link active-link" : "nav__link"}>
                                <i className='uil uil-file-alt nav__icon'></i> Skills
                            </a>
                        </li>
                        {/* ------------ Services menu button ------------ */}
                        <li className='nav__item'>
                            <a href='#services' onClick={() => setActiveNav ("#services")} className={activeNav === "#services" ? "nav__link active-link" : "nav__link"}>
                                <i className='uil uil-briefcase-alt nav__icon'></i> Services
                            </a>
                        </li>
                        {/* ------------ Portfolio menu button ------------ */}
                        <li className='nav__item'>
                            <a href='#portfolio' onClick={() => setActiveNav ("#portfolio")} className={activeNav === "#portfolio" ? "nav__link active-link" : "nav__link"}>
                                <i className='uil uil-scenery nav__icon'></i> Portfolio
                            </a>
                        </li>
                        {/* ------------ Contact menu button ------------ */}
                        <li className='nav__item'>
                            <a href='#contact' onClick={() => setActiveNav ("#contact")} className={activeNav === "#contact" ? "nav__link active-link" : "nav__link"}>
                                <i className='uil uil-message nav__icon'></i> Contact
                            </a>
                        </li>
                        {/* ------------ CV Download menu button ------------ */}
                        <li className='nav__item'>
                            <a href={CV} download onClick={() => setActiveNav("#download")} className={activeNav === "#download" ? "nav__link active-link" : "nav__link"}>
                                <i className='<uil uil-file-graph nav__icon'></i> Download
                            </a>
                        </li>
                    </ul>
                    {/* ------------ Close Menu on small devices ------------ */}
                    <i class='uil uil-times nav__close' onClick={() => showMenu (!Toggle)}></i>
                </div>
                <div className='nav__toggle' onClick={() => showMenu (!Toggle)}>
                    <i className='uil uil-apps'></i>
                </div>
            </nav>
        </header>
    );
}

export default Header;