import React from 'react';

const Socials = () => {
    return (
        <div className='home__social'>
            <a href='https://www.linkedin.com/in/alegomvil/' className='home__social-icon' target='_blank'>
                <i className='uil uil-linkedin'></i>
            </a>
            <a href='https://github.com/WuKiruru' className='home__social-icon' target='_blank'>
                <i className='uil uil-github'></i>
            </a>
        </div>
    )
}

export default Socials;