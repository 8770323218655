import Image1 from '../../assets/testimonial1.png';
import Image2 from '../../assets/testimonial2.png';
import Image3 from '../../assets/testimonial3.png';

export const Data = [
    {
        id: 1,
        name: "Susan Smith",
        title: "Web Developer",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        img: Image1,
    },
    {
        id: 2,
        name: "Anna Johnson",
        title: "Web Designer",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        img: Image2,
    },
    {
        id: 3,
        name: "Peter Jones",
        title: "Intern",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        img: Image3,
    },
];