import React from "react";
import "./Testimonials.css";
import { Data } from "./Data"; 
/*============== Swiper Slides ============*/
import { Swiper, SwiperSlide } from "swiper/react";
/*============== Swiper Slides CSS ========*/
import "swiper/css";
import "swiper/css/pagination";
/*============== Swiper Modules ===========*/
import { Pagination } from "swiper/modules";

const Testimonials = () => {
    return (
        <section className="testimonial container section">
            <h2 className="section__title">Testimonials</h2>
            <span className="section__subtitle">What our clients say</span>
            <Swiper classname="testimonial__container"
                loop={true}
                grabCursor={true}
                spaceBetween={24}
                pagination={{ clickable: true }}
                breakpoints={{
                    576: { slidesPerView: 2 },
                    768: { slidesPerView: 2, spaceBetween: 48 },
                }}
                modules={[Pagination]}
            >
                {Data.map(({ id, name, desc, img }) => {
                    return (
                        <SwiperSlide className="testimonial__card" key={id}>
                            <img src={img} alt="" className="testimonial__img" />
                            <h3 className="testimonial__name">{name}</h3>
                            <p className="testimonial__description">{desc}</p>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </section>
    )
}

export default Testimonials;