import React from "react";
import "./Footer.css";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__container container">
                <h1 className="footer__title">Jhon Doe</h1>
                <ul className="footer__list">
                    <li>
                        <a href="#about" className="footer__link">Home</a>
                    </li>
                    <li>
                        <a href="#portfolio" className="footer__link">About</a>
                    </li>
                    <li>
                        <a href="#testimonials" className="footer__link">Skills</a>
                    </li>
                </ul>
                <div className="footer__social">
                    <a href='https://www.linkedin.com/in/alegomvil/' className='footer__social-link' target='_blank'>
                        <i className='uil uil-linkedin'></i>
                    </a>
                    <a href='https://github.com/WuKiruru' className='footer__social-link' target='_blank'>
                        <i className='uil uil-github'></i>
                    </a>
                </div>
                <span className="footer__copy">&#169; Jhon Doe. All rights reserved</span>
            </div>
        </footer>
    );
};

export default Footer;