import Work1 from "../../assets/work1.jpg";
import Work2 from "../../assets/work2.jpg";
import Work3 from "../../assets/work3.jpg";
import Work4 from "../../assets/work4.jpg";
import Work5 from "../../assets/work5.jpg";

export const projectsData = [
    {
        id: 1,
        title: 'Web design',
        img: Work1,
        category: 'web',
    },
    {
        id: 2,
        title: 'Mobile design',
        img: Work2,
        category: 'app',
    },
    {
        id: 3,
        title: 'UI/UX design',
        img: Work3,
        category: 'design',
    },
    {
        id: 4,
        title: 'Mobile design',
        img: Work4,
        category: 'app',
    },
    {
        id: 5,
        title: 'UI/UX design',
        img: Work5,
        category: 'web',
    },
];

export const projectsNav = [
    {
        name: 'all',
    },
    {
        name: 'web',
    },
    {
        name: 'app',
    },
    {
        name: 'design',
    },
];