import React, { useState } from 'react';
import './Services.css';

const Services = () => {
    /*============== Toggle Modal ============*/
    const[toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    }

    return (
        <section className='services section' id='services'>
            <h2 className='section__title'>Services</h2>
            <span className='section__subtitle'>What I offer</span>
            <div className='services__container container grid'>
                {/* ------------ Service #01 ------------ */}
                <div className='services__content'>
                    <div>
                        <i className='uil uil-web-grid services__icon'></i>
                        <h3 className='services__title'>Product <br /> Designer</h3>
                    </div>
                    <span className='services__button' onClick={() => toggleTab (1)}>More Details
                        <i className='uil uil-arrow-right services__button-icon'></i>
                    </span>
                    <div className={toggleState === 1 ? 'services__modal active-modal' : 'services__modal'}>
                        <div className='services__modal-content'>
                            <i onClick={() => toggleTab (0)} className='uil uil-times services__modal-close'></i>
                            <h3 className='services__modal-title'>Product</h3>
                            <p className='services__modal-description'>Description</p>
                            <ul className='services__modal-services grid'>
                                <li className='services__modal-service'>
                                    <i className='uil uil-check-circle services__modal-icon'></i>
                                    <p className='services__modal-info'>Developing Android</p>
                                </li>
                                <li className='services__modal-service'>
                                    <i className='uil uil-check-circle services__modal-icon'></i>
                                    <p className='services__modal-info'>Developing IOS</p>
                                </li><li className='services__modal-service'>
                                    <i className='uil uil-check-circle services__modal-icon'></i>
                                    <p className='services__modal-info'>Web Design</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* ------------ Service #02 ------------ */}
                <div className='services__content'>
                    <div>
                        <i className='uil uil-arrow services__icon'></i>
                        <h3 className='services__title'>UI / UX <br /> Designer</h3>
                    </div>
                    <span className='services__button' onClick={() => toggleTab (2)}>More Details
                        <i className='uil uil-arrow-right services__button-icon'></i>
                    </span>
                    <div className={toggleState === 2 ? 'services__modal active-modal' : 'services__modal'}>
                        <div className='services__modal-content'>
                            <i onClick={() => toggleTab (0)} className='uil uil-times services__modal-close'></i>
                            <h3 className='services__modal-title'>UI / UX</h3>
                            <p className='services__modal-description'>Description</p>
                            <ul className='services__modal-services grid'>
                                <li className='services__modal-service'>
                                    <i className='uil uil-check-circle services__modal-icon'></i>
                                    <p className='services__modal-info'>AAAAAA</p>
                                </li>
                                <li className='services__modal-service'>
                                    <i className='uil uil-check-circle services__modal-icon'></i>
                                    <p className='services__modal-info'>AAAA</p>
                                </li><li className='services__modal-service'>
                                    <i className='uil uil-check-circle services__modal-icon'></i>
                                    <p className='services__modal-info'>AA</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* ------------ Service #03 ------------ */}
                <div className='services__content'>
                    <div>
                        <i className='uil uil-edit services__icon'></i>
                        <h3 className='services__title'>Visual <br /> Designer</h3>
                    </div>
                    <span className='services__button' onClick={() => toggleTab (3)}>More Details
                        <i className='uil uil-arrow-right services__button-icon'></i>
                    </span>
                    <div className={toggleState === 3 ? 'services__modal active-modal' : 'services__modal'}>
                        <div className='services__modal-content'>
                            <i onClick={() => toggleTab (0)} className='uil uil-times services__modal-close'></i>
                            <h3 className='services__modal-title'>Visual Designer</h3>
                            <p className='services__modal-description'>Description</p>
                            <ul className='services__modal-services grid'>
                                <li className='services__modal-service'>
                                    <i className='uil uil-check-circle services__modal-icon'></i>
                                    <p className='services__modal-info'>BBBBBBB</p>
                                </li>
                                <li className='services__modal-service'>
                                    <i className='uil uil-check-circle services__modal-icon'></i>
                                    <p className='services__modal-info'>BBBBB</p>
                                </li><li className='services__modal-service'>
                                    <i className='uil uil-check-circle services__modal-icon'></i>
                                    <p className='services__modal-info'>BB</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services;