import React, { useState } from 'react';
import './Qualification.css';

const Qualification = () => {
    /*============== Toggle Button ============*/
    const[toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    }

    return (
        <section className="qualification section">
            <h2 className="section__title">Qualification</h2>
            <span className="section__subtitle">What i offer</span>
            <div className="qualification__container container">
                <div className="qualification__tabs">
                    {/* ------------ Education Button ------------ */}
                    <div className={
                        toggleState === 1 
                        ? "qualification__button qualification__active button--flex" 
                        : "qualification__button button--flex"
                    }
                    onClick={() => toggleTab(1)}
                    >
                        <i className="uil uil-graduation-cap qualification__icon"></i> Education
                    </div>
                    {/* ------------ Experience Button ------------ */}
                    <div className={
                        toggleState === 2 
                        ? "qualification__button qualification__active button--flex" 
                        : "qualification__button button--flex"
                    }
                    onClick={() => toggleTab(2)}
                    >
                        <i className="uil uil-briefcase-alt qualification__icon"></i> Experience
                    </div>
                </div>
                {/* ------------ Education Section ------------ */}
                <div className="qualification__sections">
                    <div className={
                        toggleState === 1 
                        ? "qualification__content qualification__content-active" 
                        : "qualification__content"
                    }>
                        {/* ------------ Education #01 ------------ */}
                        <div className="qualification__data">
                                <div>
                                    <h3 className="qualification__title">Computer Science Engineering</h3>
                                    <span className='qualification__subtitle'>Spain - Institute</span>
                                    <div className="qualification__calendar">
                                        <i className="uil uil-calendar-alt"></i>2021 - Present
                                    </div>
                                </div>
                                <div>
                                    <span className="qualification__rounder"></span>
                                    <span className="qualification__line"></span>
                                </div>
                        </div>
                        {/* ------------ Education #02 ------------ */}
                        <div className="qualification__data">
                            <div></div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                            <div>
                                <h3 className="qualification__title">Web Design</h3>
                                <span className='qualification__subtitle'>Spain - W3</span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>2020 - 2021
                                </div>
                            </div>
                        </div>
                        {/* ------------ Education #03 ------------ */}
                        <div className="qualification__data">
                                <div>
                                    <h3 className="qualification__title">Computer Science Engineering</h3>
                                    <span className='qualification__subtitle'>3</span>
                                    <div className="qualification__calendar">
                                        <i className="uil uil-calendar-alt"></i>3
                                    </div>
                                </div>
                                <div>
                                    <span className="qualification__rounder"></span>
                                    <span className="qualification__line"></span>
                                </div>
                        </div>
                        {/* ------------ Education #04 ------------ */}
                        <div className="qualification__data">
                            <div></div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                            <div>
                                <h3 className="qualification__title">4</h3>
                                <span className='qualification__subtitle'>4</span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>4
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ------------ Experience Section ------------ */}
                    <div className={
                        toggleState === 2 
                        ? "qualification__content qualification__content-active" 
                        : "qualification__content"
                    }>
                        {/* ------------ Experience #01 ------------ */}
                        <div className="qualification__data">
                                <div>
                                    <h3 className="qualification__title">Computer Science Engineering</h3>
                                    <span className='qualification__subtitle'>5</span>
                                    <div className="qualification__calendar">
                                        <i className="uil uil-calendar-alt"></i>5
                                    </div>
                                </div>
                                <div>
                                    <span className="qualification__rounder"></span>
                                    <span className="qualification__line"></span>
                                </div>
                        </div>
                        {/* ------------ Experience #02 ------------ */}
                        <div className="qualification__data">
                            <div></div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                            <div>
                                <h3 className="qualification__title">Web Design</h3>
                                <span className='qualification__subtitle'>6</span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>6
                                </div>
                            </div>
                        </div>
                        {/* ------------ Experience #03 ------------ */}
                        <div className="qualification__data">
                                <div>
                                    <h3 className="qualification__title">Computer Science Engineering</h3>
                                    <span className='qualification__subtitle'>7</span>
                                    <div className="qualification__calendar">
                                        <i className="uil uil-calendar-alt"></i>7
                                    </div>
                                </div>
                                <div>
                                    <span className="qualification__rounder"></span>
                                    <span className="qualification__line"></span>
                                </div>
                        </div>
                        {/* ------------ Experience #04 ------------ */}
                        <div className="qualification__data">
                            <div></div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                            <div>
                                <h3 className="qualification__title">Web Design</h3>
                                <span className='qualification__subtitle'>8</span>
                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>8
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Qualification;